<script setup lang="ts">
const { slideoverOpenCreate } = useIssues()

defineShortcuts({
  F: () => slideoverOpenCreate({ data: {} }),
})
</script>

<template>
  <UTooltip text="Feedback" :shortcuts="['F']">
    <UButton
      variant="ghost"
      icon="i-mdi-message-question"
      square
      v-bind="$attrs"
      @click="slideoverOpenCreate({ data: {} })"
    />
  </UTooltip>
</template>
